import Vue from 'vue'
import VueRouter from 'vue-router'
import Parse from 'parse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/q0',
    name: 'Q0',
    component: () => import('../views/Q0.vue'),
  },
  {
    path: '/municipio',
    name: 'Municipio',
    component: () => import('../views/Municipio.vue'),
  },
  {
    path: '/personas',
    name: 'Personas',
    component: () => import('../views/Personas.vue'),
  },
  {
    path: '/q1',
    name: 'Q1',
    component: () => import('../views/Q1.vue'),
  },
  {
    path: '/q2',
    name: 'Q2',
    component: () => import('../views/Q2.vue'),
  },
  {
    path: '/q3',
    name: 'Q3',
    component: () => import('../views/Q3.vue'),
  },
  {
    path: '/q4',
    name: 'Q4',
    component: () => import('../views/Q4.vue'),
  },
  {
    path: '/q5',
    name: 'Q5',
    component: () => import('../views/Q5.vue'),
  },
  {
    path: '/q6',
    name: 'Q6',
    component: () => import('../views/Q6.vue'),
  },
  {
    path: '/q7',
    name: 'Q7',
    component: () => import('../views/Q7.vue'),
  },
  {
    path: '/q8',
    name: 'Q8',
    component: () => import('../views/Q8.vue'),
  },
  {
    path: '/q9',
    name: 'Q9',
    component: () => import('../views/Q9.vue'),
  },
  {
    path: '/q10',
    name: 'Q10',
    component: () => import('../views/Q10.vue'),
  },
  {
    path: '/q11',
    name: 'Q11',
    component: () => import('../views/Q11.vue'),
  },
  {
    path: '/q12',
    name: 'Q12',
    component: () => import('../views/Q12.vue'),
  },
  {
    path: '/q13',
    name: 'Q13',
    component: () => import('../views/Q13.vue'),
  },
  {
    path: '/q14',
    name: 'Q14',
    component: () => import('../views/Q14.vue'),
  },
  {
    path: '/q15',
    name: 'Q15',
    component: () => import('../views/Q15.vue'),
  },
  {
    path: '/q16',
    name: 'Q16',
    component: () => import('../views/Q16.vue'),
  },
  {
    path: '/q17',
    name: 'Q17',
    component: () => import('../views/Q17.vue'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/Result.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue'),
    meta: { requiresAuth: true }
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach( async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const loggedIn = Parse.User.current();

  if (requiresAuth && !loggedIn) {
    return next('/login');
  }
  next();
})

export default router
