import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueGoogleCharts from 'vue-google-charts'
import Snotify from 'vue-snotify';
import Parse from 'parse'
import moment from 'moment'

Parse.initialize(process.env.VUE_APP_PARSE_SERVER_APPLICATION_ID, process.env.VUE_APP_JS_KEY)
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL

Vue.config.productionTip = false

Vue.use(VueGoogleCharts)

Vue.use(Snotify, {
  toast: {
    timeout: 4000
  },
  global: {
    preventDuplicates: true
  }
})

moment.locale('es');
Vue.use(require('vue-moment'), {
  moment
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
