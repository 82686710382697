import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    municipio: '',
    personas: '',
    cereales: '',
    carnes: '',
    lacteos: '',
    huevos: '',
    comida: '',
    dulces: '',
    verduras: '',
    frutas: '',
    tuberculos: '',
    cafe: '',
    tee: '',
    ducha: '',
    manos: '',
    inodoro: '',
    fregadero: '',
    lavadora: '',
    lavadoraTime: '',
    aguasgrises: '',
    progress: 0,
    appbar: false,
    user: {
      name: '',
      cellphone: '',
      email: ''
    }
  },
  getters: {
    getCereales: state => {
      return state.cereales
    },
    getCarnes: state => {
      return state.carnes
    },
    getLacteos: state => {
      return state.lacteos
    },
    getHuevos: state => {
      return state.huevos
    },
    getComida: state => {
      return state.comida
    },
    getDulces: state => {
      return state.dulces
    },
    getVerduras: state => {
      return state.verduras
    },
    getFrutas: state => {
      return state.frutas
    },
    getTuberculos: state => {
      return state.tuberculos
    },
    getCafe: state => {
      return state.cafe
    },
    getTee: state => {
      return state.tee
    },
    getMunicipio: state => {
      return state.municipio
    },
    getPersonas: state => {
      return state.personas
    },
    getDucha: state => {
      return state.ducha
    },
    getManos: state => {
      return state.manos
    },
    getInodoro: state => {
      return state.inodoro
    },
    getFregadero: state => {
      return state.fregadero
    },
    getLavadora: state => {
      return state.labadora
    },
    getlavadoraTime: state => {
      return state.labadoraTime
    },
    getAguasGrises: state => {
      return state.aguasgrises
    },
    getAppBar: state => {
      return state.appbar
    },
    getUser: state => {
      return state.user
    },
  },
  mutations: {
    setCereales(state, value) {
      state.cereales = value;
    },
    setCarnes(state, value) {
      state.carnes = value;
    },
    setLacteos(state, value) {
      state.lacteos = value;
    },
    setHuevos(state, value) {
      state.huevos = value;
    },
    setComida(state, value) {
      state.comida = value;
    },
    setDulces(state, value) {
      state.dulces = value;
    },
    setVerduras(state, value) {
      state.verduras = value;
    },
    setFrutas(state, value) {
      state.frutas = value;
    },
    setTuberculos(state, value) {
      state.tuberculos = value;
    },
    setCafe(state, value) {
      state.cafe = value;
    },
    setTee(state, value) {
      state.tee = value;
    },
    setMunicipio(state, value) {
      state.municipio = value;
    },
    setPersonas(state, value) {
      state.personas = value;
    },
    setDucha(state, value) {
      state.ducha = value;
    },
    setManos(state, value) {
      state.manos = value;
    },
    setInodoro(state, value) {
      state.inodoro = value;
    },
    setFregadero(state, value) {
      state.fregadero = value;
    },
    setLavadora(state, value) {
      state.lavadora = value;
    },
    setlavadoraTime(state, value) {
      state.lavadoraTime = value;
    },
    setAguasGrises(state, value) {
      state.aguasgrises = value;
    },
    setAppBar(state, value) {
      state.appbar = value;
    },
    setUser(state, value) {
      state.user = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
