<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="!$store.state.appbar"
    >
    <v-progress-linear
        v-model="$store.state.progress"
        absolute
        bottom
        color="grey darken-4"
        height="7"
      ></v-progress-linear>

      <div class="d-flex align-center boxhead">
        <router-link to="/" class="white--text">
          <img :src="require('./assets/logok.png')" alt="Logo" width="100">
        </router-link>
      </div>

      <v-spacer></v-spacer>

    <v-btn
       class="white--text mr-2"
       rounded
       color="black"
       outlined
       v-if="auth"
       to="/admin"
      >
        Ver resultados <v-icon right>mdi-chart-bar</v-icon>
      </v-btn>

      <v-btn
       class="white--text mr-2"
       rounded
       color="black"
       outlined
       v-if="auth"
       to="/contacts"
      >
        Ver Contactos <v-icon right>mdi-account-group</v-icon>
      </v-btn>

      <v-btn
       class="white--text"
       rounded
       color="black"
       outlined
       v-if="auth"
       @click="closeSession()"
      >
        Cerrar sesión <v-icon right>mdi-close</v-icon>
      </v-btn>
      
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-footer color="white" class="pl-0 pr-0 pb-0">
      
      <!--Waves Container-->
      <div style="width: 100%;">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(33,150,243,0.7" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(33,150,243,0.5)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(33,150,243,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#2196f3" />
          </g>
        </svg>
      </div>
      <!--Waves end-->
      <v-col
        class="text-center white--text"
        cols="12"
        style="position: absolute; align-self: flex-end;"
      >
        <span v-if="!$store.state.appbar">{{ new Date().getFullYear() }} — <strong>Huella hídrica</strong></span>
      </v-col>
    </v-footer>

    <vue-snotify></vue-snotify>

  </v-app>
</template>

<script>
import Parse from 'parse'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    auth: Parse.User.current()
  }),

  mounted() {
    if (this.$route.fullPath == '/app') {
      this.$store.state.appbar = true
      this.$router.push('/')
    }
  },

  methods: {
    closeSession() {
      Parse.User.logOut();
      window.location.reload()
    },
  },
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
</style>

<style lang="css">

  .myFont {
    font-family: 'Quicksand' !important;
  }

  .swal-modal {
      font-family: Helvetica Neue, Arial, sans-serif;
  }

  .boxhead a {
      color: #FFFFFF;
      text-decoration: none;
  }

  .inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

  /* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:80px;
    min-height:80px;
  }
  .content {
    height:30vh;
  }
}

</style>